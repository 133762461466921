import type { ComponentType, ReactElement } from 'react';
import React from 'react';
import { MessageDescriptor } from '@lingui/core';
import { Trans } from '@lingui/react';
import styled from 'styled-components';

import { Box, BoxProps } from '@rover/kibble/core';
import { A11yHidden, DSTokenMap, MQ } from '@rover/kibble/styles';
import type { Props as CommonProps } from '@rover/react-lib/src/components/buttons/SelectButton/SelectButton.types';
import CalloutBadge from '@rover/react-lib/src/components/callouts/CalloutBadge';
import Icon from '@rover/react-lib/src/components/typography/Icon/Icon';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

export type Props = CommonProps & {
  allowSelectMultiple: boolean;
  icon?: string | ComponentType;
  title: string | MessageDescriptor;
  label?: string | MessageDescriptor;
  inputGroupName?: string;
  displayAs?: ('compact' | null | undefined) | 'expanded';
  className?: string;
  ariaLabel?: string;
  required: boolean;
  bigLabel?: boolean;
};

type StyledLabelProps = {
  selected: boolean;
  hasIcon: boolean;
  disabled?: boolean;
  displayAs?: ('compact' | null | undefined) | 'expanded';
  onClick?: () => void;
};

export const SELECT_BUTTON_IS_SELECTED_CLASS = 'SelectButton--isSelected';

const SelectButtonLabelBox = React.forwardRef<HTMLDivElement, StyledLabelProps & BoxProps>(
  (
    { children, hasIcon, selected, disabled, displayAs, onClick, sx, className, ...other },
    ref
  ): JSX.Element => (
    <Box
      ref={ref as React.ForwardedRef<HTMLElement>}
      className={className}
      onClick={onClick}
      sx={{
        padding: '15px 25px',
        margin: 0,
        display: 'flex',
        fontSize: DSTokenMap.TEXT_100_FONT_SIZE,
        boxShadow: `inset 0 0 0 ${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY.toString()}`,
        borderRadius: DSTokenMap.BORDER_RADIUS_SECONDARY.toString(),
        color: DSTokenMap.TEXT_COLOR_TERTIARY.toString(),
        borderColor: DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY.toString(),
        whiteSpace: 'normal',
        position: 'relative',
        flex: 1,
        fontWeight: 'normal',
        userSelect: 'none',
        hyphens: 'auto',
        cursor: 'pointer',
        '&:not(:first-of-type)': {
          marginLeft: 12,
        },
        '@media (pointer: fine)': {
          '&:hover': {
            color: DSTokenMap.TEXT_COLOR_SECONDARY.toString(),
            borderColor: DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_PRESSED.toString(),
            boxShadow: `inset 0 0 0 ${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_PRESSED.toString()}`,

            // Changes color of icons to match text colors when in hover state
            '& > div > div > svg': {
              fill: DSTokenMap.TEXT_COLOR_SECONDARY.toString(),
            },
            '& > div > div > i': {
              color: DSTokenMap.TEXT_COLOR_SECONDARY.toString(),
            },
            ...(disabled && {
              cursor: 'not-allowed',
              color: DSTokenMap.TEXT_COLOR_TERTIARY.toString(),
            }),
          },
        },
        '&:active': {
          borderColor: DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE.toString(),
          backgroundColor: DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString(),
          boxShadow: `inset 0 0 0 2px ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE.toString()}`,
        },
        '&:focus-within': {
          zIndex: 0,
          outlineOffset: 2,
          outline: '5px auto Highlight',
        },
        ...(selected && {
          boxShadow: `inset 0 0 0 2px ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE.toString()}`,
          color: DSTokenMap.TEXT_COLOR_SECONDARY.toString(),
          borderColor: DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE.toString(),
          backgroundColor: DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString(),

          // Changes color of icons to match text colors when in selected state
          '& > div > div > svg': {
            fill: DSTokenMap.INTERACTIVE_TEXT_COLOR_PRIMARY.toString(),
          },
          '& > div > div > i': {
            color: DSTokenMap.INTERACTIVE_TEXT_COLOR_PRIMARY.toString(),
          },

          '&:hover': {
            borderColor: DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE_PRESSED.toString(),
            backgroundColor: DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString(),
            boxShadow: `inset 0 0 0 2px ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE_PRESSED.toString()} !important`,
          },
        }),
        ...(displayAs === 'compact' && {
          justifyContent: hasIcon ? 'flex-start' : 'center',
        }),
        ...(displayAs === 'expanded' && {
          justifyContent: 'center',
        }),
        ...(!displayAs && {
          justifyContent: 'center',
          [MQ.MD_UP.toString()]: {
            justifyContent: hasIcon ? 'flex-start' : 'center',
          },
        }),
        ...(disabled && {
          backgroundColor: DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString(),
          borderColor: DSTokenMap.INTERACTIVE_BORDER_COLOR_DISABLED.toString(),
          boxShadow: `inset 0 0 0 1px ${DSTokenMap.INTERACTIVE_BORDER_COLOR_DISABLED.toString()}`,
          color: DSTokenMap.TEXT_COLOR_DISABLED.toString(),
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  )
);

export const SelectButtonLabel = styled(SelectButtonLabelBox)``;

type WrapperProps = {
  displayAs?: ('compact' | null | undefined) | 'expanded';
};
const WrapperBox: React.FC<WrapperProps & BoxProps> = ({
  children,
  displayAs,
  sx,
  className,
  ...other
}): JSX.Element => (
  <Box
    className={className}
    sx={{
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      width: '100%',
      ...((displayAs === 'compact' && {
        flexDirection: 'row',
      }) ||
        (displayAs === 'expanded' && {
          flexDirection: 'column',
        }) || {
          flexDirection: 'column',
          [MQ.MD_UP.toString()]: {
            flexDirection: 'row',
          },
        }),
      ...sx,
    }}
    {...other}
  >
    {children}
  </Box>
);
export const Wrapper = styled(WrapperBox)``;

type IconWrapperProps = {
  displayAs?: ('compact' | null | undefined) | 'expanded';
};

const IconWrapperBox: React.FC<IconWrapperProps & BoxProps> = ({
  children,
  displayAs,
  sx,
  ...other
}): JSX.Element => (
  <Box
    sx={{
      minWidth: '24px',
      maxWidth: '24px',
      minHeight: '24px',
      maxHeight: '24px',
      ...((displayAs === 'compact' && {
        marginBottom: 0,
        marginRight: '10px',
      }) ||
        (displayAs === 'expanded' && {
          marginBottom: '6px',
        }) || {
          marginBottom: '6px',
          [MQ.MD_UP.toString()]: {
            marginBottom: 0,
            marginRight: '10px',
          },
        }),
      '& > svg': {
        minWidth: '24px',
        maxWidth: '24px',
        minHeight: '24px',
        maxHeight: '24px',
        fill: DSTokenMap.TEXT_COLOR_TERTIARY.toString(),
      },
      '& > i': {
        fontSize: '24px',
        color: DSTokenMap.TEXT_COLOR_TERTIARY.toString(),
      },
      ...sx,
    }}
    {...other}
  >
    {children}
  </Box>
);

export const IconWrapper = styled(IconWrapperBox)<IconWrapperProps>``;

const StyledHiddenInput = styled.input`
  ${A11yHidden}
`;
type ButtonLabelProps = {
  icon?: boolean;
  displayAs?: ('compact' | null | undefined) | 'expanded';
};

const ButtonLabelBox: React.FC<ButtonLabelProps & BoxProps> = ({
  children,
  displayAs,
  icon,
  sx,
  className,
  ...other
}): JSX.Element => (
  <Box
    className={className}
    sx={{
      ...((displayAs === 'compact' && {
        width: '100%',
        textAlign: icon ? 'left' : 'center',
      }) ||
        (displayAs === 'expanded' && {
          width: '100%',
          textAlign: 'center',
        }) || {
          width: '100%',
          textAlign: 'center',
          [MQ.MD_UP.toString()]: {
            textAlign: icon ? 'left' : 'center',
          },
        }),
      ...sx,
    }}
    {...other}
  >
    {children}
  </Box>
);

export const ButtonLabel = styled(ButtonLabelBox)``;

const TitleBox: React.FC<BoxProps & { selected: boolean; bigLabel?: boolean }> = ({
  children,
  sx,
  className,
  selected,
  bigLabel,
  ...other
}): JSX.Element => (
  <Box
    className={className}
    sx={{
      fontSize: bigLabel
        ? DSTokenMap.TEXT_200_FONT_SIZE.toString()
        : DSTokenMap.TEXT_100_FONT_SIZE.toString(),
      fontWeight: selected ? 600 : 400,
      [MQ.XS.toString()]: {
        fontSize: DSTokenMap.TEXT_100_FONT_SIZE.toString(),
      },
      ...sx,
    }}
    {...other}
  >
    {children}
  </Box>
);

export const Title = styled(TitleBox)``;
const StyledCalloutBadge = styled(CalloutBadge)`
  position: absolute;
  top: -4px;
  left: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const renderIcon = (ButtonIcon: string | ComponentType) =>
  typeof ButtonIcon === 'string' ? <Icon icon={ButtonIcon} /> : <ButtonIcon />;

const renderSubtitle = (subtitle) => {
  if (!subtitle) return null;
  return (
    <div>
      <Trans id={subtitle} />
    </div>
  );
};

const renderTitle = (title, subtitle, displayAs, icon, selected, bigLabel) => {
  if (!title && !subtitle) return null;
  return (
    <ButtonLabel icon={!!icon} displayAs={displayAs}>
      <Title selected={selected} bigLabel={bigLabel}>
        <Trans id={title} />
      </Title>
      {renderSubtitle(subtitle)}
    </ButtonLabel>
  );
};

const SelectButton = ({
  allowSelectMultiple,
  selected,
  disabled,
  required,
  icon,
  title,
  subtitle,
  label,
  inputGroupName,
  displayAs,
  onClick,
  className,
  ariaLabel,
  bigLabel,
  ...other
}: Props): ReactElement => {
  const { i18n } = useI18n();
  return (
    <SelectButtonLabel
      disabled={disabled}
      displayAs={displayAs}
      hasIcon={!!icon}
      onClick={!disabled ? onClick : () => {}}
      selected={selected}
      // NOTE(Ryan) This is an eyeroll of a hack but its the quickest/tersest way I could think of
      //            to open up selection styles to modification by consuming components
      className={`${className || ''} ${selected ? SELECT_BUTTON_IS_SELECTED_CLASS : ''}`}
      data-testid="styled-label"
      {...other}
    >
      {label && <StyledCalloutBadge value={label} />}
      <Wrapper displayAs={displayAs}>
        {icon && <IconWrapper displayAs={displayAs}>{renderIcon(icon)}</IconWrapper>}
        {renderTitle(title, subtitle, displayAs, icon, selected, bigLabel)}
        <StyledHiddenInput
          type={allowSelectMultiple ? 'checkbox' : 'radio'}
          name={inputGroupName}
          checked={selected}
          required={required}
          aria-required={required}
          aria-checked={selected}
          // Force title to by of type string since TypeScript has trouble figuring out which overload to use
          // https://github.com/microsoft/TypeScript/issues/14107
          aria-label={ariaLabel || i18n._((title || subtitle) as string)}
          onChange={!disabled ? onClick : () => {}}
          data-testid="styled-input"
        />
      </Wrapper>
    </SelectButtonLabel>
  );
};

SelectButton.defaultProps = {
  value: '',
  allowSelectMultiple: true,
  selected: false,
  disabled: false,
  required: false,
  title: '',
  subtitle: '',
  label: '',
  onClick: () => {},
};

export default SelectButton;
