import React from 'react';
import type { Props as RheoStatProps } from 'rheostat';
import Rheostat from 'rheostat';
import styled, { css } from 'styled-components';

import { DSTokenMap } from '@rover/kibble/styles';

import ProgressBar from './ProgressBar';
import SliderHandle from './SliderHandle';

export type WrapperProps = {
  className?: string;
  barHeight: number;
  handleHeight: number;
  handleHeightMobile: number;
};

const cssInCoarseQuery = (style) =>
  css`
    @media (pointer: coarse) {
      ${style}
    }
  `;

const Wrapper = styled.div<WrapperProps>`
  .rheostat {
    ${({ handleHeightMobile }) => cssInCoarseQuery(`width: calc(100% - ${handleHeightMobile}px);`)}

    overflow: visible;
    width: calc(100% - ${(props) => props.handleHeight}px);
    margin-left: ${(props) => props.barHeight}px;
  }

  .rheostat-background {
    background-color: ${DSTokenMap.BACKGROUND_COLOR_TERTIARY.toString()};
    position: relative;
  }

  .rheostat-progress {
    background-color: ${DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_PRIMARY_ACTIVE.toString()};
    position: absolute;
  }

  .rheostat-handle {
    ${({ handleHeightMobile }) =>
      cssInCoarseQuery(
        `
        height: ${handleHeightMobile}px;
        width: ${handleHeightMobile}px;
        `
      )}

    -webkit-appearance: none !important;
    height: ${(props) => props.handleHeight}px;
    width: ${(props) => props.handleHeight}px;
    padding: 0;
    border: ${DSTokenMap.BORDER_WIDTH_PRIMARY} solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
    border-radius: ${DSTokenMap.BORDER_RADIUS_ROUND};
    z-index: 2;
    cursor: pointer;
    background: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};

    &::-webkit-slider-thumb {
      -webkit-appearance: none !important;
    }
  }

  .rheostat-horizontal {
    height: ${(props) => props.barHeight}px;

    .rheostat-background {
      ${({ handleHeightMobile }) =>
        cssInCoarseQuery(`width: calc(100% + ${handleHeightMobile}px);`)}

      height: ${(props) => props.barHeight}px;
      width: calc(100% + ${(props) => props.handleHeight}px);
      top: 0;
      left: -${(props) => props.barHeight}px;
      border-radius: ${(props) => props.barHeight / 2}px;
    }

    .rheostat-progress {
      height: ${(props) => props.barHeight}px;
      top: 0;
      margin-left: -${(props) => props.barHeight}px;
      border-top-left-radius: ${(props) => props.barHeight / 2}px;
      border-bottom-left-radius: ${(props) => props.barHeight / 2}px;
    }

    .rheostat-handle {
      ${({ handleHeightMobile, barHeight }) =>
        cssInCoarseQuery(`top: -${(handleHeightMobile - barHeight) / 2}px;`)}
      top: -${(props) => (props.handleHeight - props.barHeight) / 2}px;
      margin-left: -${(props) => props.barHeight}px;
    }
  }
`;

export type Props = {
  className?: string;
  barHeight?: number;
  handleHeight?: number;
  handleHeightMobile?: number;
  prefix?: string;
  [key: string]: any;
};

export const PrefixContext = React.createContext('');

const Slider = ({
  className = '',
  barHeight = 10,
  handleHeight = 20,
  handleHeightMobile,
  prefix,
  ...other
}: Props): JSX.Element => {
  const wrapperProps: WrapperProps = {
    className,
    barHeight: barHeight || 0,
    handleHeight: handleHeight || 0,
    handleHeightMobile: handleHeightMobile || handleHeight || 0,
  };
  const sliderProps: RheoStatProps = { ...other, progressBar: ProgressBar, handle: SliderHandle };
  return (
    <Wrapper {...wrapperProps} data-testid="slider-wrapper">
      <PrefixContext.Provider value={prefix || ''}>
        <Rheostat {...sliderProps} />
      </PrefixContext.Provider>
    </Wrapper>
  );
};

export default Slider;
