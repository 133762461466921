import type { ComponentType } from 'react';
import React, { useId } from 'react';
import styled from 'styled-components';

import { CheckCircle } from '@rover/icons';
import { DSTokenMap, MQ } from '@rover/kibble/styles';
import SelectButton, { IconWrapper } from '@rover/react-lib/src/components/buttons/SelectButton';
import type { ExtractProps } from '@rover/types/src/ExtractProps';

type WrapperProps = {
  isVertical: boolean;
};

const HORIZONTAL_SPACING = DSTokenMap.SPACE_4X;

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: ${({ isVertical }) => (isVertical ? 'column' : '')};
  flex-wrap: wrap;
  margin-left: ${({ isVertical }) => (isVertical ? '0' : `-${HORIZONTAL_SPACING}`)};
`;

type ButtonWrapperProps = {
  isVertical?: boolean;
};

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  flex: 1 1 0px;
  position: relative;
  margin-left: ${({ isVertical }) => (isVertical ? '0' : HORIZONTAL_SPACING)};
  margin-top: ${({ isVertical }) => (isVertical ? DSTokenMap.SPACE_4X : DSTokenMap.SPACE_1X)};

  ${MQ.MD_UP.toString()} {
    max-width: 50%;
  }
`;

type StyledSelectButtonProps = ExtractProps<typeof SelectButton> & {
  selected?: boolean;
  disabled?: boolean;
};

const getStyle =
  ({
    selectedAndDisabled,
    selectedAndNotDisabled,
    unselectedAndDisabled,
    unselectedAndNotDisabled,
  }) =>
  ({ disabled, selected }: { disabled?: boolean; selected?: boolean }) => {
    if (selected) {
      if (disabled) {
        return selectedAndDisabled;
      }
      return selectedAndNotDisabled;
    }
    if (disabled) {
      return unselectedAndDisabled;
    }
    return unselectedAndNotDisabled;
  };

export const StyledSelectButton = styled(SelectButton)<StyledSelectButtonProps>`
  height: 100%;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;

  &:hover ${IconWrapper} {
    & svg {
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : '')};
      fill: ${({ disabled, selected }) =>
        getStyle({
          selectedAndDisabled: '',
          selectedAndNotDisabled: '',
          unselectedAndDisabled: DSTokenMap.TEXT_COLOR_TERTIARY.toHexString(),
          unselectedAndNotDisabled: DSTokenMap.TEXT_COLOR_SECONDARY.toHexString(),
        })({ disabled, selected })};
    }
  }

  ${IconWrapper} {
    & svg {
      fill: ${({ disabled, selected }) =>
        getStyle({
          selectedAndDisabled: DSTokenMap.TEXT_COLOR_TERTIARY.toHexString(),
          selectedAndNotDisabled: DSTokenMap.INTERACTIVE_TEXT_COLOR_PRIMARY.toHexString(),
          unselectedAndDisabled: DSTokenMap.TEXT_COLOR_DISABLED.toHexString(),
          unselectedAndNotDisabled: DSTokenMap.TEXT_COLOR_TERTIARY.toHexString(),
        })({ disabled, selected })};
    }
  }
`;

export const StyledCheckmark = styled(CheckCircle)`
  height: 36px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  width: 36px;
`;

export type Option = {
  icon?: ComponentType<unknown> | string;
  key: string;
  title: string;
  subtitle?: string;
  ariaLabel?: string;
};

export type Props = {
  options: Option[];
  isVertical?: boolean;
  onChange: (arg0: string) => void;
  selected: Record<string, boolean>;
  showCheckmark?: boolean;
};

const MultiSelectButtonGroup = ({
  isVertical = false,
  onChange,
  options,
  selected,
  showCheckmark = false,
  ...other
}: Props): JSX.Element => {
  const inputGroupName = useId();
  return (
    <Wrapper {...other} isVertical={isVertical} data-qa-id="multi-select-wrapper">
      {options.map((option) => (
        <ButtonWrapper
          key={option.key}
          isVertical={isVertical}
          data-qa-id={`button-wrapper-${option.key}`}
        >
          <StyledSelectButton
            {...option}
            data-qa-id={`select-button-${option.key}`}
            selected={selected[option.key]}
            onClick={() => onChange(option.key)}
            inputGroupName={inputGroupName}
          />
          {showCheckmark && selected[option.key] ? (
            <StyledCheckmark data-qa-id={`checkmark-${option.key}`} />
          ) : null}
        </ButtonWrapper>
      ))}
    </Wrapper>
  );
};

export default MultiSelectButtonGroup;
