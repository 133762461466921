import React from 'react';
import styled from 'styled-components';

import { CollapseSmall, ExpandSmall } from '@rover/icons';
import { Color } from '@rover/kibble/styles';

export const StyledExpandSmall = styled(ExpandSmall)`
  fill: ${Color.GREEN_700.toString()};
  height: 100%;
  width: 25px;
`;
export const StyledCollapseSmall = styled(CollapseSmall)`
  fill: ${Color.GREEN_700.toString()};
  height: 100%;
  width: 25px;
`;
export type Props = {
  isOpen?: boolean;
};

const IconSelectArrow = ({ isOpen }: Props): JSX.Element =>
  isOpen ? <StyledCollapseSmall /> : <StyledExpandSmall />;

export default IconSelectArrow;
