// Adapted from https://github.com/airbnb/rheostat/blob/master/src/SliderHandle.jsx
import React, { useContext } from 'react';
import { t } from '@lingui/macro';

import type { Orientation } from '@rover/types/src/Image';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import { PrefixContext } from './Slider';

export type Props = {
  disabled?: boolean;
  handleRef?: () => void;
  orientation?: Orientation;
  role?: string;
  tabIndex?: number;
  'aria-valuemax'?: number;
  'aria-valuemin'?: number;
  'aria-valuenow'?: number;
  'aria-disabled'?: boolean;
  'aria-valuetext'?: string;
  'data-handle-key'?: number;
  onClick?: () => void;
  onKeyDown?: () => void;
  onMouseDown?: () => void;
  onTouchStart?: () => void;
};

const getAriaLabel = (dataHandleKey) => {
  return dataHandleKey === 0 ? t`Slider handle (minimum)` : t`Slider handle (maximum)`;
};

const SliderHandle = ({
  handleRef,
  'data-handle-key': dataHandleKey,
  ...other
}: Props): JSX.Element => {
  const { i18n } = useI18n();
  const prefix = useContext(PrefixContext);

  const handleProps = other;
  handleProps['aria-valuetext'] = `${prefix}${other['aria-valuenow']}`;

  return (
    <input
      type="range"
      ref={handleRef}
      data-handle-key={dataHandleKey}
      aria-label={i18n._(getAriaLabel(dataHandleKey))}
      {...handleProps}
    />
  );
};

SliderHandle.defaultProps = {
  disabled: false,
  handleRef: null,
  orientation: 'horizontal',
  role: undefined,
  tabIndex: undefined,
  'aria-valuenow': undefined,
  'data-handle-key': undefined,
  'aria-valuemax': undefined,
  'aria-valuemin': undefined,
  'aria-disabled': undefined,
  'aria-valuetext': undefined,
  onClick: undefined,
  onKeyDown: undefined,
  onMouseDown: undefined,
  onTouchStart: undefined,
};

export default SliderHandle;
