import React from 'react';
import styled, { css } from 'styled-components';

import { DSTokenMap } from '@rover/kibble/styles';
import type { SelectOption } from '@rover/types';

import type { Props as DropdownProps } from '../Dropdown/Dropdown';
import Dropdown, { Button, Menu, MenuOption } from '../Dropdown/Dropdown';
import { DropdownState } from '../Dropdown/Dropdown.constants';

import IconOption from './IconOption';
import IconSelectArrow from './IconSelectArrow';

export type Props = Omit<DropdownProps, 'options'> & {
  value: string;
  options: SelectOption[];
  listMaxHeight?: string;
};
const StyledDropdown = styled(Dropdown)<DropdownProps & { listMaxHeight?: string }>`
  &.${DropdownState.OPEN.toString()} {
    ${Button} {
      background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
    }
  }

  ${Button} {
    cursor: pointer;
    padding: ${DSTokenMap.SPACE_1X};
    box-shadow: inset 0 0 0 ${DSTokenMap.BORDER_WIDTH_PRIMARY}
      ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
    border-radius: ${DSTokenMap.BORDER_RADIUS_SECONDARY};

    &:hover {
      background-color: ${DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};
    }
  }

  ${Menu} {
    margin-top: 1px;
    padding: 0;
    width: 100%;

    border: 0;
    border-radius: ${DSTokenMap.BORDER_RADIUS_SECONDARY};
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);

    ${({ listMaxHeight }) =>
      listMaxHeight &&
      css`
        max-height: ${listMaxHeight};
        overflow-y: scroll;
      `}
  }

  ${MenuOption} {
    padding: 0;

    &.${DropdownState.FOCUSED_OPTION.toString()}, &.${DropdownState.SELECTED_OPTION.toString()} {
      background-color: ${DSTokenMap.BACKGROUND_COLOR_TERTIARY.toString()};
    }
  }
`;

const IconSelect = ({ options, ...props }: Props): JSX.Element => {
  const formatOptions = options.map((option) => ({
    value: option.value,
    label: <IconOption data={option} />,
  }));
  return <StyledDropdown {...props} arrow={IconSelectArrow} options={formatOptions} />;
};

IconSelect.defaultProps = Dropdown.defaultProps;

export default IconSelect;
