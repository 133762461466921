import React from 'react';
import styled from 'styled-components';

import { DSTokenMap } from '@rover/kibble/styles';

type StyledLabelProps = {
  fontSize?: string;
  mb?: string;
  color?: string;
};

export const StyledLabel = styled.label<StyledLabelProps>`
  font-weight: 700;
  font-size: ${({ fontSize }) => fontSize || 'unset'};
  display: inline-flex;
  ${(props) => `
    color: ${props.color};
    margin-bottom: ${props.mb};
  `}
`;

export type Props = React.ComponentPropsWithoutRef<'label'> & StyledLabelProps;

const Label = ({
  children,
  mb = DSTokenMap.SPACE_2X.toString(),
  color = DSTokenMap.TEXT_COLOR_SECONDARY.toString(),
  ...other
}: Props): JSX.Element => (
  <StyledLabel mb={mb} color={color} {...other}>
    {children}
  </StyledLabel>
);

export default Label;
