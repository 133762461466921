/* eslint-disable react/no-multi-comp */
import React, { FC, forwardRef, useEffect, useId, useRef, useState } from 'react';
import { Role } from 'react-native';
import classnames from 'classnames';
import styled from 'styled-components';

import { DropdownSmall } from '@rover/icons';
import { Box, BoxProps } from '@rover/kibble/core';
import { A11yHidden, DSTokenMap, ZIndex } from '@rover/kibble/styles';
import KEYS from '@rover/react-lib/src/constants/keys.constants';
import { isReactNative } from '@rover/rsdk/src/modules/Env/react';

import { DropdownState } from './Dropdown.constants';
import { OptionProp } from './Dropdown.types';
import DropdownOption from './DropdownOption';

export type Props = {
  align?: 'left' | 'right';
  arrow?: React.ComponentType<{ isOpen?: boolean }>;
  className?: string;
  direction?: 'top' | 'bottom';
  id?: string;
  initialIsOpen?: boolean;
  label?: React.ReactNode;
  onChange: (value: string) => void;
  onOpen?: () => void;
  options: OptionProp[];
  showLabel?: boolean;
  value: string;
  buttonType?: 'submit' | 'reset' | 'button';
  placeholder?: React.ReactNode;
};

// We need to keep this components as styled-components because they are used in IconSelect.tsx
export const MenuOption = styled(DropdownOption)``;

const UL = (props: React.ComponentProps<typeof Box>): JSX.Element => <Box as="ul" {...props} />;

export const Menu = styled(UL)<BoxProps>``;

type BoxButtonProps = BoxProps &
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
// We are not using the same styles as the kibble button yet.
// We need a component here in order to be referenced when using a Custom Style Dropdown
// eslint-disable-next-line rover/prefer-kibble-components
const BoxButton = forwardRef<HTMLElement, BoxButtonProps>(({ children, sx, ...other }, ref) => {
  return (
    <Box
      as="button"
      sx={{
        position: 'relative',
        border: 'none',
        borderRadius: 0,
        textAlign: 'left',
        width: '100%',
        padding: `${DSTokenMap.SPACE_1X} ${DSTokenMap.SPACE_6X} ${DSTokenMap.SPACE_1X} ${DSTokenMap.SPACE_3X}`,
        backgroundColor: 'transparent',
        ...(sx || {}),
      }}
      {...other}
      ref={ref as any}
    >
      {children}
    </Box>
  );
});

export const Button = styled(BoxButton)``;

const Dropdown: FC<Props> = ({
  align = 'left',
  direction = 'bottom',
  initialIsOpen = false,
  showLabel = true,
  options,
  value,
  id: $id,
  onOpen,
  onChange,
  arrow: Arrow,
  className,
  label,
  buttonType,
  placeholder,
}) => {
  const staticId = useId();
  const id = $id || staticId;
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const listRef = useRef<HTMLElement | null>(null);
  const isSelectedOption = (option: OptionProp): boolean => option.value === value;
  const initialIndex = options.findIndex(isSelectedOption);

  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [focusedIndex, setFocusedIndex] = useState(initialIndex > -1 ? initialIndex : 0);
  const closeTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (initialIndex > -1) {
      setFocusedIndex(initialIndex);
    }
  }, [initialIndex]);

  const handleWrapperBlur = (): void => {
    // Close Dropdown on next tick using setTimeout.
    // First need to check if a child has received focus as this blur event will fire prior to the new focus event.
    const timeout = setTimeout(() => {
      setIsOpen(false);
    });

    closeTimeoutRef.current = timeout;
  };

  const handleWrapperFocus = (): void => {
    // If a child (menu option) receives focus, do not close Dropdown.
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
      closeTimeoutRef.current = null;
    }
  };

  const handleToggle = (): void => {
    const isOpening = !isOpen;

    setIsOpen(!isOpen);

    if (isOpening) {
      if (onOpen) onOpen();
    } else {
      buttonRef.current?.focus();
    }
  };

  const handleChange = (newIndex: number): void => {
    const selectedOption = options[newIndex];
    setFocusedIndex(newIndex);
    onChange?.(selectedOption.value);
  };

  const handleMenuItemFocus = (index: number): void => {
    setFocusedIndex(index);

    if (process.env.JS_ENV_CLIENT) {
      const optionEl = document.getElementById(`option-${id}-${index}`);
      optionEl?.focus();
    }
  };

  const handleKeydown = (
    event: React.KeyboardEvent<HTMLUListElement | HTMLButtonElement>
  ): void => {
    const prevIndex = focusedIndex - 1;
    const nextIndex = focusedIndex + 1;

    // Selection should not follow focus
    // Source: https://www.w3.org/TR/wai-aria-practices/#kbd_selection_follows_focus
    switch (event.key) {
      case KEYS.UP:
        event.preventDefault();

        if (prevIndex >= 0) {
          handleMenuItemFocus(prevIndex);
        }

        break;

      case KEYS.DOWN:
        event.preventDefault();

        if (nextIndex < options.length) {
          handleMenuItemFocus(nextIndex);
        }

        break;

      case KEYS.ENTER: {
        event.preventDefault();
        handleChange(focusedIndex);
        handleToggle();
        break;
      }

      default:
        break;
    }
  };

  const handleButtonKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>): void => {
    // While the menu is open, the button element passes key handling to the menu handler
    if (isOpen) {
      handleKeydown(event);
      return;
    }

    // When the menu is closed but focused, a press on Enter or the up/down arrows should open it
    switch (event.key) {
      case KEYS.DOWN:
      case KEYS.UP:
      case KEYS.ENTER:
        event.preventDefault();
        handleToggle();
        break;

      default:
        break;
    }
  };

  const handleClick = (index: number): void => {
    if (typeof index !== 'undefined') {
      handleChange(index);
      handleToggle();
    }
  };

  const classes = classnames(className, {
    [DropdownState.OPEN.toString()]: isOpen,
  });

  const selectedOption: OptionProp = options.find(isSelectedOption) || options[0];
  // React bubbles up blur and focus events to the parent.
  return (
    <Box
      {...(isReactNative()
        ? {}
        : { className: classes, onFocus: handleWrapperFocus, onBlur: handleWrapperBlur })}
    >
      {label && !showLabel ? (
        <Box as="span" id={`label-${id}`} {...A11yHidden}>
          {label}
        </Box>
      ) : (
        label && (
          <Box as="span" id={`label-${id}`}>
            {label}
          </Box>
        )
      )}
      <Box position="relative" zIndex={isOpen ? ZIndex.DROPDOWN.toString() : ''}>
        <Button
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          aria-labelledby={label ? `label-${id}` : undefined}
          aria-owns={`id-${id}-menu`}
          id={id}
          onClick={handleToggle}
          onKeyDown={handleButtonKeyDown}
          ref={buttonRef as any}
          {...(buttonType ? { type: buttonType } : {})}
        >
          {!!placeholder && !value ? placeholder : selectedOption && selectedOption.label}

          <Box
            position="absolute"
            top="0"
            right={DSTokenMap.SPACE_2X}
            bottom="0"
            sx={{ pointerEvents: 'none' }}
          >
            {Arrow ? (
              <Arrow isOpen={isOpen} />
            ) : (
              <DropdownSmall
                style={{
                  fill: 'inherit',
                  height: '100%',
                  verticalAlign: 'middle',
                  width: DSTokenMap.SPACE_3X,
                }}
              />
            )}
          </Box>
        </Button>
        <Menu
          aria-activedescendant={`option-${id}-${focusedIndex}`}
          aria-labelledby={label ? `label-${id}` : undefined}
          id={`id-${id}-menu`}
          onKeyDown={handleKeydown}
          ref={listRef as any}
          role={isReactNative() ? 'list' : ('listbox' as Role)}
          tabIndex={-1}
          sx={{
            position: 'absolute',
            margin: 0,
            padding: 0,
            borderRadius: DSTokenMap.BORDER_RADIUS_SECONDARY,
            ...(direction === 'top' && align === 'left' ? { borderBottomLeftRadius: 0 } : {}),
            ...(direction === 'top' && align === 'right' ? { borderBottomRightRadius: 0 } : {}),
            ...(direction === 'bottom' && align === 'left' ? { borderTopLeftRadius: 0 } : {}),
            ...(direction === 'bottom' && align === 'right' ? { borderTopRightRadius: 0 } : {}),
            ...(!isOpen ? { display: 'none' } : {}),
            ...(direction === 'bottom' ? { top: '100%' } : {}),
            ...(direction === 'top' ? { bottom: '100%' } : {}),
            ...(align === 'right' ? { right: 0 } : {}),
            ...(align === 'left' ? { left: 0 } : {}),
          }}
        >
          {options.map((option, index) => {
            const isSelected = option === selectedOption;
            const isFocused = index === focusedIndex;
            const isFirstOption = index === 0;
            const isLastOption = index === options.length - 1;

            const borderStyles = {
              ...(isFirstOption && { borderTopLeftRadius: 'inherit' }),
              ...(isFirstOption && { borderTopRightRadius: 'inherit' }),
              ...(isLastOption && { borderBottomLeftRadius: 'inherit' }),
              ...(isLastOption && { borderBottomRightRadius: 'inherit' }),
            };

            return (
              <MenuOption
                id={`option-${id}-${index}`}
                isFocused={isFocused}
                isSelected={isSelected}
                key={option.value}
                option={option}
                onClick={() => handleClick(index)}
                data-qa-id={`option-${option.value}`}
                sx={borderStyles}
              />
            );
          })}
        </Menu>
      </Box>
    </Box>
  );
};

export default Dropdown;
