import React from 'react';

export type Props = {
  style: {
    width: string;
  };
};

// NOTE(Ryan) The purpose of this component is to clean up the styling of the progress bar.
//            Without this tweak the border-radius looks squished for small values, and the
//            progress bar doesn't go all the way behind the slider handle
const ProgressBar = ({ style, ...other }: Props): JSX.Element => {
  const props: Props = { ...other, style: { ...style, width: `calc(${style.width} + 10px)` } };
  return <div {...props} data-testid="styled-progress-bar" />;
};

export default ProgressBar;
