import React, { useId } from 'react';
import styled from 'styled-components';

import { DSTokenMap, Spacing } from '@rover/kibble/styles';

export const StyledFieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
`;

export const StyledLegend = styled.div`
  font-weight: 700;
  color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
  margin-bottom: ${Spacing.S.toString()};
`;

export type Props = {
  legend?: string;
  legendId?: string;
} & React.ComponentProps<typeof StyledFieldset>;

const Fieldset = ({
  children,
  legend = undefined,
  legendId,
  ...other
}: React.PropsWithChildren<Props>): JSX.Element => {
  const defaultId = useId();
  const actualLegendId = legendId || defaultId;
  const fieldsetProps = { ...other };

  if (legend || legendId) {
    fieldsetProps['aria-labelledby'] = actualLegendId;
  }

  return (
    <StyledFieldset {...fieldsetProps} data-testid="styled-fieldset">
      {legend && <StyledLegend id={actualLegendId}>{legend}</StyledLegend>}
      {children}
    </StyledFieldset>
  );
};
export default Fieldset;
