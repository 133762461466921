import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { Box, BoxProps } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';

import { DropdownState } from './Dropdown.constants';
import { OptionProp } from './Dropdown.types';

const LI = (props: React.ComponentProps<typeof Box>): JSX.Element => <Box as="li" {...props} />;

const ListItem = styled(LI)<BoxProps>`
  margin: 0;
  padding: ${DSTokenMap.SPACE_2X} ${DSTokenMap.SPACE_3X};

  background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
  list-style-type: none;
  text-align: left;

  &:hover {
    background-color: ${DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};
  }

  &.is-focused {
    color: ${DSTokenMap.INTERACTIVE_TEXT_COLOR_PRIMARY_ACTIVE.toString()};
  }
`;

export type Props = {
  className?: string;
  isFocused: boolean;
  isSelected: boolean;
  option: OptionProp;
} & React.ComponentProps<typeof ListItem>;

const DropdownOption = ({
  className,
  isFocused,
  isSelected,
  option,
  ...props
}: Props): JSX.Element => {
  // Explore alternatives to classnames: https://roverdotcom.atlassian.net/browse/DEV-42053
  const classes = classnames(className, {
    [DropdownState.FOCUSED_OPTION.value]: isFocused,
    [DropdownState.SELECTED_OPTION.value]: isSelected,
  });
  return (
    <ListItem className={classes} aria-selected={isSelected} role="option" tabIndex={-1} {...props}>
      {option.label}
    </ListItem>
  );
};

export default DropdownOption;
