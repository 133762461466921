import React, { useId } from 'react';
import styled from 'styled-components';

import { MQ } from '@rover/kibble/styles';
import SelectButton from '@rover/react-lib/src/components/buttons/SelectButton';
import type { ExtractProps } from '@rover/types/src/ExtractProps';

type WrapperProps = {
  isVertical: boolean;
  ariaLabelledBy?: string;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: ${(props) => (props.isVertical ? 'column' : '')};
`;

export type StyledSelectButtonProps = ExtractProps<typeof SelectButton> & {
  isVertical?: boolean;
};

export const StyledSelectButton = styled(SelectButton)<StyledSelectButtonProps>`
  &:not(:first-of-type) {
    margin-top: ${(props) => (props.isVertical ? '12px' : '')};
    margin-left: ${(props) => (props.isVertical ? '0' : '')};
  }

  ${MQ.MD_UP.toString()} {
    max-width: 50%;
  }
`;

export type Props<T> = {
  value?: T;
  buttons: StyledSelectButtonProps[];
  onChange: (arg0: T) => void;
  required?: boolean;
  inputGroupName?: string;
  isVertical?: boolean;
  className?: string;
  id?: string;
  ariaLabelledBy?: string;
  bigLabel?: boolean;
};

function SingleSelectButtonGroup<T = unknown>({
  value,
  buttons,
  onChange,
  isVertical = false,
  required = false,
  ariaLabelledBy,
  bigLabel,
  ...other
}: Props<T>): JSX.Element {
  const defaultInputGroupName = useId();
  return (
    <Wrapper
      {...other}
      isVertical={isVertical || false}
      role="radiogroup"
      aria-labelledby={ariaLabelledBy || ''}
    >
      {buttons.map((button: StyledSelectButtonProps) => (
        <StyledSelectButton
          {...button}
          isVertical={isVertical}
          key={button.value}
          selected={button.value === value && !button.disabled}
          onClick={() => onChange(button.value)}
          allowSelectMultiple={false}
          inputGroupName={other.inputGroupName || defaultInputGroupName}
          required={required}
          bigLabel={bigLabel}
        />
      ))}
    </Wrapper>
  );
}

export default SingleSelectButtonGroup;
