import React from 'react';
import { MessageDescriptor } from '@lingui/core';
import classnames from 'classnames';
import styled from 'styled-components';

import { DSTokenMap, FONT_FAMILY, FontSize, Spacing } from '@rover/kibble/styles';
import type { Props as LabelAndErrorFormGroupProps } from '@rover/react-lib/src/components/utils/LabelAndErrorFormGroup';
import LabelAndErrorFormGroup from '@rover/react-lib/src/components/utils/LabelAndErrorFormGroup';
import { SENSITIVE_DATA_CLASS } from '@rover/react-lib/src/constants/sensitiveData.constants';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

const PLACEHOLDER_VALUE = 'placeholder';

const renderPlaceholder = (placeholder) => {
  if (placeholder) {
    return (
      <option disabled value={PLACEHOLDER_VALUE}>
        {placeholder}
      </option>
    );
  }
  return null;
};

export const StyledSelect = styled.select`
  background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
  background-image: none;
  border-radius: ${DSTokenMap.BORDER_RADIUS_SECONDARY};
  border: ${DSTokenMap.BORDER_WIDTH_PRIMARY} solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
  color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
  display: block;
  font-family: ${FONT_FAMILY};
  font-size: ${FontSize.M.toString()};
  height: 40px;
  line-height: 1.6;
  padding: ${Spacing.S.toString()} ${Spacing.M.toString()} ${Spacing.S.toString()}
    ${Spacing.XS.toString()};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  box-shadow: none;

  &:disabled {
    border: ${DSTokenMap.BORDER_WIDTH_PRIMARY} solid
      ${DSTokenMap.INTERACTIVE_BORDER_COLOR_DISABLED.toString()};
    background-color: ${DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_DISABLED.toString()};
    color: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
    cursor: not-allowed;
    opacity: 1;
  }
`;

export type Option<T extends string | number> = {
  value: T;
  title: MessageDescriptor | string;
  disabled?: boolean;
};

export type Props<T extends string | number> = {
  value?: T;
  options: Array<Option<T>>;
  onChange: (arg0: T) => void;
  placeholder?: string;
  className?: string;
  sensitive?: boolean;
  autoComplete?: string;
  disabled?: boolean;
};

const Select = <T extends string | number>({
  value = '' as T,
  options,
  placeholder = '',
  onChange,
  className = '',
  sensitive = false,
  disabled,
  ...other
}: Props<T>): JSX.Element => {
  const { i18n } = useI18n();

  return (
    <StyledSelect
      className={classnames(className, {
        [SENSITIVE_DATA_CLASS]: sensitive,
      })}
      value={value || value === 0 ? value : PLACEHOLDER_VALUE}
      onChange={(e) => onChange(e.target.value as T)}
      disabled={disabled}
      {...other}
    >
      {renderPlaceholder(placeholder)}
      {options.map((option) => (
        <option
          value={option.value}
          key={option.value}
          disabled={typeof option.disabled === 'boolean' ? option.disabled : false}
        >
          {i18n._(option.title as string)}
        </option>
      ))}
    </StyledSelect>
  );
};

export { Select as UnwrappedSelect };

export default <T extends string | number>(
  props: LabelAndErrorFormGroupProps & { validationType: 'popover' | 'inline' } & Omit<
      Props<T>,
      'placeholder'
    >
): JSX.Element => <LabelAndErrorFormGroup {...props} InputComponent={Select} />;
