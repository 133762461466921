import React from 'react';
import styled from 'styled-components';

import { Box } from '@rover/kibble/core';
import { Color, DSTokenMap, Font, Spacing } from '@rover/kibble/styles';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import type { SelectOption } from '@rover/types';

export const Wrapper = styled(Box)`
  cursor: pointer;
  display: flex !important;
  min-height: 54px;
  padding: ${Spacing.S.toString()} ${Spacing.M.toString()} !important;
`;
export const IconWrapper = styled(Box)`
  display: flex;

  svg {
    align-self: center;
    /* NOTE(Ryan) this icon just doesn't look centered visually without this 1px adjustment */
    bottom: 1px;
    fill: ${Color.GREEN_700.toString()};
    flex: 0 0 22px;
    height: 22px;
    margin-right: ${Spacing.M.toString()};
    position: relative;
    width: 22px;
  }
`;
const LabelWrapper = styled(Box)`
  color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;
export const Title = styled(Box)`
  font-size: ${Font.FontSize.L.toString()};
  font-weight: ${Font.FontWeight.HEAVY.toString()};
  height: 22px;
`;
export const SubTitle = styled(Box)`
  font-size: ${Font.FontSize.M.toString()};
  height: 16px;
`;
export type Props = {
  data: SelectOption;
};

const IconOption = ({ data: { title, subtitle, icon: OptionIcon } }: Props): JSX.Element => {
  const { i18n } = useI18n();

  return (
    <Wrapper>
      {OptionIcon && (
        <IconWrapper>
          <OptionIcon />
        </IconWrapper>
      )}
      <LabelWrapper>
        <Title>{i18n._(title as string)}</Title>
        {subtitle && <SubTitle>{i18n._(subtitle as string)}</SubTitle>}
      </LabelWrapper>
    </Wrapper>
  );
};

export default IconOption;
