// eslint-disable-next-line max-classes-per-file
import React, { FC, useId } from 'react';
import styled from 'styled-components';

import { Boarding, Daycare, DogTraining, DropIn, Sitting, Walking } from '@rover/icons';
import { DSTokenMap, MQ, Spacing } from '@rover/kibble/styles';
import Fieldset from '@rover/react-lib/src/components/formFields/Fieldset';
import IconSelect from '@rover/react-lib/src/components/formFields/IconSelect';
import Select from '@rover/react-lib/src/components/formFields/Select';
import SingleSelectButtonGroup, {
  Props as SingleSelectButtonGroupProps,
  StyledSelectButton,
} from '@rover/react-lib/src/components/formFields/SingleSelectButtonGroup';
import Label from '@rover/react-lib/src/components/typography/Label';
import { PRICE_FILTER } from '@rover/shared/js/constants/searchPage.constants';
import { SERVICE_TYPE_CHOICES } from '@rover/shared/js/constants/service';
import type { SelectOption, ServiceTypeOption, ServiceTypeSlug } from '@rover/types';
import Enum from '@rover/types/src/Enum';

export const FilterLabel: React.FC<{
  children: React.ReactNode;
  onClick?: (e: MouseEvent) => void;
}> = styled(Label)`
  display: block;
  margin-bottom: ${Spacing.S.toString()};
  width: 100%;
`;

export class ServiceFilterType extends Enum<string> {
  static BUTTONS = new ServiceFilterType('buttons');

  static DROPDOWN = new ServiceFilterType('dropdown');

  static ICON_DROPDOWN = new ServiceFilterType('icon-dropdown');

  static isLocked = true;
}

export type Props = {
  label?: string;
  onChange:
    | ((arg0: { maxprice: number; minprice: number; serviceType: ServiceTypeSlug }) => void)
    | ((arg0: { serviceType: ServiceTypeSlug }) => void);
  searchFilters: {
    serviceType: ServiceTypeSlug;
  };
  filterType?: ServiceFilterType;
  serviceTypeOptions: ServiceTypeOption[];
  displayMode?: ('horizontal' | null | undefined) | 'vertical';
  priceFilterMaxValue?: number;
  priceFilterMinValue?: number;
  className?: string;
  serviceTypeFilterId?: string;
  listMaxHeight?: string;
};

export const SERVICE_TYPE_ICONS = {
  [SERVICE_TYPE_CHOICES.boarding]: Boarding,
  [SERVICE_TYPE_CHOICES.traveling]: Sitting,
  [SERVICE_TYPE_CHOICES.dropIn]: DropIn,
  [SERVICE_TYPE_CHOICES.dayCare]: Daycare,
  [SERVICE_TYPE_CHOICES.walking]: Walking,
  [SERVICE_TYPE_CHOICES.training]: DogTraining,
};

const StyledFieldset = styled(Fieldset)`
  min-inline-size: auto;
`;

type StyledButtonGroupProps = SingleSelectButtonGroupProps<string> & {
  buttonsPerRow: number;
  spaceBetweenButtons: number;
};

const StyledButtonGroup = styled(SingleSelectButtonGroup)<StyledButtonGroupProps>`
  hyphens: auto;
  margin-bottom: ${DSTokenMap.SPACE_4X};
  overflow-wrap: break-word;
  word-wrap: break-word;
  justify-content: space-between;

  ${StyledSelectButton} {
    flex-basis: calc(
      ${({ buttonsPerRow, spaceBetweenButtons }) =>
        `${100 / buttonsPerRow}% - ${spaceBetweenButtons}px`}
    );
    padding: ${DSTokenMap.SPACE_4X} ${DSTokenMap.SPACE_3X};
    margin-left: 0;
    max-width: calc(
      ${({ buttonsPerRow, spaceBetweenButtons }) =>
        `${100 / buttonsPerRow}% - ${spaceBetweenButtons}px`}
    );
  }

  ${MQ.XXS.toString()} {
    ${StyledSelectButton} {
      padding: ${DSTokenMap.SPACE_4X} ${DSTokenMap.SPACE_1X};
    }
  }
`;

const ServiceTypeFilter: FC<Props> = ({
  filterType = ServiceFilterType.BUTTONS,
  priceFilterMaxValue = PRICE_FILTER.DEFAULT_MAX_PRICE,
  priceFilterMinValue = PRICE_FILTER.DEFAULT_MIN_PRICE,
  searchFilters,
  onChange,
  displayMode,
  className,
  serviceTypeOptions,
  label,
  listMaxHeight,
  serviceTypeFilterId: serviceTypeFilterIdProp,
}) => {
  const serviceTypeFilterId = useId();
  const inputGroupName = useId();
  const selectedServiceType = searchFilters.serviceType;

  const handleChange = (serviceTypeSlug: ServiceTypeSlug): void => {
    const newSelectedServiceType = searchFilters.serviceType !== serviceTypeSlug;

    if (newSelectedServiceType) {
      onChange({
        maxprice: priceFilterMaxValue,
        minprice: priceFilterMinValue,
        serviceType: serviceTypeSlug,
      });
    }
  };

  const renderServiceButtons = (
    options: SelectOption[],
    $serviceTypeFilterId: string
  ): JSX.Element => {
    const spaceBetweenButtons = displayMode === 'horizontal' ? 12 : 6;

    const buttonsPerRow = (() => {
      if (!displayMode) {
        return 2;
      }

      if (options.length <= 5) {
        return options.length;
      }

      return 3;
    })();

    const buttonRows: SelectOption[][] = options.reduce(
      (accumulator: SelectOption[][], curButton, index) => {
        const buttonRowIndex = Math.floor(index / buttonsPerRow);
        const buttonRowOptions: SelectOption = {
          ...curButton,
          subtitle: undefined,
          displayAs: displayMode === 'horizontal' ? 'expanded' : null,
        };

        if (accumulator[buttonRowIndex]) {
          accumulator[buttonRowIndex].push(buttonRowOptions);
        } else {
          accumulator[buttonRowIndex] = [buttonRowOptions];
        }

        return accumulator;
      },
      []
    );

    return (
      <StyledFieldset aria-labelledby={$serviceTypeFilterId}>
        {buttonRows.map((rowButtons) => (
          <StyledButtonGroup
            buttons={rowButtons}
            value={selectedServiceType}
            onChange={(v) => handleChange(v as ServiceTypeSlug)}
            key={rowButtons[0].value}
            buttonsPerRow={buttonsPerRow}
            spaceBetweenButtons={spaceBetweenButtons}
            inputGroupName={inputGroupName} // both rows get same group name to group inputs together
            className={className}
          />
        ))}
      </StyledFieldset>
    );
  };

  let filterComponent;

  const dropDownOptions =
    serviceTypeOptions &&
    serviceTypeOptions.map((sto) => ({
      ...sto,
      icon: SERVICE_TYPE_ICONS[sto.value] || Boarding,
    }));

  let labelProps: Record<string, unknown> = {
    id: serviceTypeFilterIdProp || serviceTypeFilterId,
  };

  switch (filterType) {
    case ServiceFilterType.DROPDOWN:
      filterComponent = (
        <Select
          id={serviceTypeFilterIdProp || serviceTypeFilterId}
          name="service-type"
          value={selectedServiceType}
          // eslint-disable-next-line rover/prefer-inline-validation
          validationType="popover"
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          options={dropDownOptions.map(({ subtitle, icon, ...other }) => other)}
          onChange={(v) => handleChange(v)}
        />
      );
      labelProps = { htmlFor: serviceTypeFilterIdProp || serviceTypeFilterId };
      break;
    case ServiceFilterType.ICON_DROPDOWN:
      filterComponent = (
        <IconSelect
          id={serviceTypeFilterIdProp || serviceTypeFilterId}
          value={selectedServiceType}
          options={dropDownOptions}
          onChange={(v) => handleChange(v as ServiceTypeSlug)}
          listMaxHeight={listMaxHeight}
        />
      );
      labelProps = { htmlFor: serviceTypeFilterIdProp || serviceTypeFilterId };
      break;
    case ServiceFilterType.BUTTONS:
      filterComponent = renderServiceButtons(
        dropDownOptions,
        serviceTypeFilterIdProp || serviceTypeFilterId
      );
      break;
    default:
      filterComponent = renderServiceButtons(
        dropDownOptions,
        serviceTypeFilterIdProp || serviceTypeFilterId
      );
      break;
  }

  return (
    <>
      {label && (
        <FilterLabel
          onClick={(e) => {
            e.preventDefault();
          }}
          {...labelProps}
        >
          {label}
        </FilterLabel>
      )}
      {filterComponent}
    </>
  );
};

export default ServiceTypeFilter;
