import Enum from '@rover/types/src/Enum';

export class DropdownState extends Enum<string> {
  static OPEN = new DropdownState('is-open');

  static FOCUSED_OPTION = new DropdownState('is-focused');

  static SELECTED_OPTION = new DropdownState('is-selected');
}
export default {
  DropdownState,
};
